<style lang="scss" scoped>
.toast .toast-message {
  z-index: 99999999999 !important;
}
label[for="iframesCardNumber"],
label[for="iframesCardExpiration"],
label[for="iframesCardCvv"] {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  color: #555;
  line-height: 1.5;
}
.cvv {
  font-family: sans-serif;
  box-sizing: border-box;
  display: block;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
}
.abb-button {
  height: min-content;
}
#ss-banner {
  background: transparent
    url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-shield@2x.png?raw=true)
    no-repeat left center;
  height: 40px;
  background-size: 280px 34px;
  margin-bottom: 7px;
}

user agent stylesheet div {
  display: block;
}
#heart-payment-info {
  display: none;
}
#heart-loading-payment {
  margin-top: 25%;
}
.form-row {
  margin-top: 10px;
}

.form-wrapper {
  display: block;
  width: 300px;
  margin: 10px auto;
}
.alert-fixed {
  position: fixed;
  top: 6%;
  left: 0;
  width: 100%;
  background: #dc3545 !important;
  text-align: center;
  z-index: 99999999;
  height: 5%;
}
#payment-info {
  display: none;
}
#loading-payment {
  margin-top: 25%;
}
#tokenframe {
  width: 100% !important;
}
@media (max-width: 400px) {
  .paymemt-label {
    font-size: 0.8rem !important;
  }
}
.hidden {
  display: none;
}
#cc_error {
  color: red;
}

#f-payement {
  .alert-danger-dim {
    background: #f7cece90;
  }

  .table-bg-success {
    background: #b2facb70;
    border-radius: 10px;
  }
  p {
    font-size: 12px;
  }
  .abbre-code {
    display: flex;
    label {
      line-height: 50px;
      width: 255px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .promo-code {
    display: flex;
    label {
      line-height: 50px;
      width: 255px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .card {
    .card-body {
      padding: 1.25rem;
      .btn-right {
        float: right;
      }
      .cleft {
        float: left;
      }
    }
  }
  .payment-type {
    list-style: none;
    padding: 0;
    li {
      padding: 10px;
      background: #dfdfe5ad;
      margin-bottom: 10px;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #dfdfe5ad;
      }
      &.active {
        border: 1px solid #211a68;
        box-sizing: border-box;
        // &:hover {
        //   background: rgb(87, 86, 86);
        //   color: white;
        // }
      }
      .checked-payment {
        position: absolute;
        height: 40px;
        width: 30px;
        top: 0;
        left: 0;
        border-top: 0px solid transparent;
        border-bottom: 41px solid transparent;
        border-left: 40px solid #211a68;
      }
      label {
        margin: 0;
        // margin-left: 5%;
        span {
          margin-left: 10px;
          font-weight: 500;
          font-size: 14px;
        }
        i {
          color: black;
        }
      }
    }
  }
}
</style>
<template>
  <section id="f-payement">
    <v-countries-modal
      :li-scrolls="id_scroll_list_country"
      :show-modal="showCountriesWithPhoneCode"
      v-on:closemodal="toggleCountriesPhoneCode"
      :type="true"
    ></v-countries-modal>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <!-- <h5>Enrollment: {{ $route.params.enroll_id }}</h5> -->
            <label
              >Applicant : <strong> {{ enrollData.applicant }}</strong></label
            >
            <br />
            <label
              >TCN : <strong>{{ $route.params.tcn }}</strong></label
            >
            <br />
            <label
              >Archive Expiry Date :
              <strong>{{
                dateFormat(this.enrollData.archival_expiry_date)
              }}</strong></label
            >
          </div>
        </div>
        <hr />

        <div class="row">
          <div
            class="col-lg-12 col-md-12 mb-2"
            v-if="
              (is_both_card &&
                getEnableOriRate &&
                getServiceOriCode.success == true &&
                updateServiceOriCode != '') ||
                !getEnableOriRate
            "
          >
            <h4>{{ getfClientRate.title }}</h4>
            <table
              class="table table-bg-success"
              v-if="!is_archival && !is_renew"
            >
              <thead>
                <tr>
                  <th scope="col" style="width: 2%">Qty</th>
                  <th scope="col" class="text-right">Price</th>
                  <th scope="col" class="text-right">Tax</th>
                  <th scope="col" class="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    {{
                      getfClientRate.service.is_drug_testing
                        ? parseInt(getQty)
                        : getfClientRate.service.default_qty
                    }}
                  </th>
                  <td class="text-right">${{ getfClientRate.rate }}</td>
                  <td
                    class="text-right"
                    v-if="getfClientRate.service.is_drug_testing"
                  >
                    ${{
                      (getfClientRate.rate * parseInt(getQty))
                        | taxAmount(getfClientRate.tax_percentage)
                    }}
                  </td>
                  <td class="text-right" v-else>
                    ${{
                      getfClientRate.rate
                        | taxAmount(getfClientRate.tax_percentage)
                    }}
                  </td>
                  <td
                    class="text-right"
                    v-if="getfClientRate.service.is_drug_testing"
                  >
                    ${{ getTotalRateAmount }}
                  </td>
                  <td class="text-right" v-else>${{ getTotalRateAmount }}</td>
                </tr>
              </tbody>
            </table>
            <div
              class="row"
              v-if="
                getfClientRate.service.is_drug_testing &&
                  !is_archival &&
                  !is_renew
              "
            >
              <div class="col-md-4 mt-5">
                <label for=""><h6>Quantity :</h6></label>
              </div>
              <div class="input-group mb-2 col-md-8">
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  @change="setQty"
                  v-model="qty"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="col-lg-12 col-md-12"
            v-if="
              getfClientRate.has_additional_rate > 0 &&
                !getfClientRate.service.is_drug_testing &&
                !is_archival &&
                !is_renew
            "
          >
            <h4>
              Additional Cards :
              <i
                class="fa fa-minus text-dark"
                @click="miuQty_()"
                style="cursor: pointer"
              ></i>
              <span
                style="
                  border: 1px solid #ccc;
                  padding-right: 10px;
                  padding-left: 10px;
                  margin-left: 10px;
                  margin-right: 10px;
                "
                >{{ getAddQty }}</span
              >
              <i
                class="fa fa-plus text-dark"
                style="cursor: pointer"
                @click="addQty_()"
              ></i>
            </h4>
            <table class="table table-bg-success">
              <thead>
                <tr>
                  <th scope="col" style="width: 2%">Qty</th>
                  <th scope="col" class="text-right">Price</th>
                  <th scope="col" class="text-right">Tax</th>
                  <th scope="col" class="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{{ getAddQty }}</th>
                  <td class="text-right">
                    ${{ getfClientRate.additional_rate }}
                  </td>
                  <td class="text-right">
                    ${{
                      (getfClientRate.additional_rate * getAddQty)
                        | taxAmount(getfClientRate.tax_percentage)
                    }}
                  </td>
                  <td class="text-right">${{ getTotalAdditionalAmount }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-12">
            <div class="row" v-if="is_archival" style="margin-top: 10px">
              <div class="col-6">
                <div class="form-group">
                  <label for="">Archival Period:</label>
                  <select
                    :disabled="custom_archival"
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="archival_period"
                    @change="setArchival"
                  >
                    <option value="">None</option>

                    <option
                      v-for="(citem, index) in getArchivalRates"
                      :key="index"
                      :value="citem.archival_period.id"
                    >
                      {{ citem.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 m-0" v-if="is_renew">
                <div class="form-group">
                  <label for=""> Select Date</label>
                  <input
                    :disabled="custom_archival"
                    type="date"
                    v-model="selected_renew_date"
                    class="form-control"
                    id="inputdate"
                    name="inputdate"
                    @change="setRenewDate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <h5 class="float-right" v-if="getPromoCodeDiscountRate > 0">
              Discount ({{ getPromoCodeDiscountRate }}%): ${{
                getTotalDiscountAmount
              }}
            </h5>
          </div>
          <div class="col-12">
            <h5
              class="float-right"
              v-if="
                front_status && getAbbreviationCode.data.show_payment_option
              "
            >
              Total: ${{ getTotalServiceAmount }}
            </h5>

            <div
              v-if="
                !getAbbreviationCode.success &&
                  abbreviation_code_error &&
                  updateAbbreviationCode != ''
              "
              class="alert alert-custom alert-danger-dim alert-shadow fade show gutter-b"
              role="alert"
            >
              <div class="alert-text" v-html="getAbbreviationCode.msg"></div>
            </div>
          </div>

          <p
            v-html="getSettingNote.abbreviation_code_note"
            v-if="front_status"
          ></p>
          <div class="col-12 abbre-code">
            <label
              >Company Abbreviation Code :
              <strong
                class="text-uppercase"
                v-if="
                  getAbbreviationCode.success == true &&
                    abbreviation_code_error &&
                    updateAbbreviationCode != ''
                "
                >{{ updateAbbreviationCode }}</strong
              >
            </label>
            <div
              class="input-group mt-2"
              :hidden="getAbbreviationCode.success == true"
            >
              <input
                @input="updateAbCode"
                class="form-control text-uppercase"
                v-model="abree_code"
                v-mask="'XXXXXXXXXX'"
                type="text"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <div class="input-group-append abb-button">
                <button
                  class="btn btn-success"
                  @click="checkAbbreviationCode()"
                  type="button"
                  style="z-index: 0"
                >
                  Use
                </button>
              </div>
            </div>
          </div>

          <div
            class="col-md-12"
            v-if="
              getAbbreviationCode.success == true &&
                abbreviation_code_error &&
                updateAbbreviationCode != ''
            "
            style="margin-top: 10px"
          >
            <div class="row">
              <div class="col-md-12">
                <ul style="list-style: none; padding: 0">
                  <li>
                    Name :
                    <b>{{ getAbbreviationCode.data.company_name }}</b>
                  </li>
                  <li>
                    Address :
                    <b> {{ getAbbreviationCode.data.address }}</b>
                  </li>
                  <li class="mt-2">
                    <a
                      style="cursor: pointer"
                      @click="removeAbbCode()"
                      class="btn btn-danger"
                    >
                      <i class="fa fa-times"></i> Remove Company Abbreviation Code
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div class="col-6" v-if="this.paymentMethod">
            <div class="form-group">
              <label for="">Select payment mode:</label>
              <select class="form-control" @change="updatePaymentMode($event)">
                <option value="">Select payment mode</option>
                <option value="1">On-site</option>
                <option value="2">Credit Card</option>
              </select>
            </div>
          </div> -->
          <div class="col-12">
            <div
              v-if="
                !getPromoCode.success &&
                  promo_code_error &&
                  updatePromoCode != ''
              "
              class="alert alert-custom alert-danger-dim alert-shadow fade show gutter-b"
              role="alert"
            >
              <div class="alert-text" v-html="getPromoCode.msg"></div>
            </div>
          </div>
          <div class="col-12 promo-code" v-if="front_status">
            <label>
              <span
                v-if="
                  getPromoCode.success == true &&
                    !promo_code_error &&
                    updatePromoCode != ''
                "
                >Promo Code ({{ getPromoCode.data.title }}) :</span
              >
              <span v-else>Promo Code :</span>
              <strong
                v-if="
                  getPromoCode.success == true &&
                    !promo_code_error &&
                    updatePromoCode != ''
                "
                class="text-uppercase"
                >{{ updatePromoCode }}</strong
              >
            </label>
            <div
              class="input-group mb-3"
              :hidden="getPromoCode.success == true"
            >
              <input
                @input="updatePRCode"
                class="form-control text-uppercase"
                v-model="promo_code"
                v-mask="'XXXXXXXXXX'"
                type="text"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-success"
                  @click="checkPromoCode()"
                  type="button"
                  style="z-index: 0"
                >
                  Use
                </button>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="archivalItem != 0">
            <table class="table table-bg-success">
              <thead>
                <tr>
                  <th scope="col-4" class="text-right">Price</th>
                  <th scope="col-4" class="text-right">Tax</th>
                  <th scope="col-4" class="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-right">${{ archivalItem.rate }}</td>
                  <td class="text-right">
                    ${{
                      archivalItem.rate | taxAmount(archivalItem.tax_percentage)
                    }}
                  </td>
                  <td class="text-right">${{ archival_total }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12">
            <div class="form-group mt-0 mb-0" style="display: inline-flex">
              <input
                type="checkbox"
                name="custom_archival"
                id="custom_archival"
                v-model="custom_archival"
                @change="set_custom_archival"
              />
              <label
                class="ml-2"
                for="custom_archival"
                style="margin-top: 11px; font-weight: 900"
                >Customize Archive Date</label
              >
            </div>
          </div>
          <div class="col-md-12" v-if="custom_archival">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for=""> Select Date</label>
                  <input
                    type="date"
                    v-model="custom_archival_date"
                    class="form-control"
                    id="inputdate"
                    name="inputdate"
                    @change="setCustomArchivalDate"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for=""> Total Amount</label>
                  <input
                    type="number"
                    v-model="custom_archival_price"
                    class="form-control"
                    id="inputdate"
                    name="inputdate"
                    @input="setCustomPrice"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-12"
            v-if="
              getPromoCode.success == true &&
                !promo_code_error &&
                updatePromoCode != ''
            "
            style="margin-top: 10px"
          >
            <div class="row">
              <div class="col-md-12">
                <ul style="list-style: none; padding: 0">
                  <!-- <li>
                    Promo title:
                    <b>{{ getPromoCode.data.title }}</b>
                  </li> -->
                  <li class="mt-2">
                    <a
                      style="cursor: pointer"
                      @click="removePromoCode()"
                      class="btn btn-danger"
                    >
                      <i class="fa fa-times"></i> Remove Promo Code
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div
              v-if="
                !getServiceOriCode.success &&
                  service_ori_code_error &&
                  updateServiceOriCode != ''
              "
              class="col-md-12 alert alert-custom alert-danger-dim alert-shadow fade show gutter-b"
              role="alert"
            >
              <div class="alert-text" v-html="getServiceOriCode.msg"></div>
            </div>
          </div>

          <div
            class="col-md-8 col-lg-12 col-sm-12 col-12 abbre-code"
            v-if="getEnableOriRate"
          >
            <label
              >ORI :
              <strong
                class="text-uppercase"
                onchange="change_ori"
                v-if="getServiceOriCode.success == true"
                >{{ updateServiceOriCode }}</strong
              >
            </label>
            <div
              class="input-group mb-3"
              :hidden="getServiceOriCode.success == true"
            >
              <input
                v-if="!front_status"
                class="form-control text-uppercase"
                v-model="service_ori"
                type="text"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                @input="updateOri"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-success"
                  @click="checkServiceOri()"
                  type="button"
                  style="z-index: 0"
                >
                  Use
                </button>
              </div>
            </div>
          </div>

          <div
            class="col-md-12"
            v-if="getServiceOriCode.success == true"
            style="margin-top: 10px"
          >
            <div class="row">
              <div class="col-md-5">
                <ul style="list-style: none; padding: 0">
                  <li>
                    Name :
                    <b>{{ getServiceOriCode.data.profession }}</b>
                  </li>
                  <!-- <li>
                Address : <b> {{ getAbbreviationCode.data.address }}</b>
              </li> -->
                  <li class="mt-2" v-if="!front_status">
                    <a
                      style="cursor: pointer"
                      @click="removeServiceOri()"
                      class="btn btn-danger"
                    >
                      <i class="fa fa-times"></i> Remove ORI
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-6 mt-5 abbre-code" v-if="archivalItem != 0">
          <label> Total : </label>
          <div class="input-group mt-2">
            <input
              @input="changeArchivalAmount"
              type="number"
              class="form-control"
              v-model="getTotalArchivalRateAmount"
            />
          </div>
        </div> -->
      </div>

      <div
        class="col-md-6 content d-flex flex-column flex-column-fluid new-service"
        v-if="getServiceProviderData.payment_method == 'cardpointe'"
        :style="
          getServiceProviderData.payment_method == 'cardpointe' && paymentMethod
            ? 'display:block;'
            : 'display:none!important;'
        "
      >
        <div class="row">
          <div class="col-md-12" id="loading-payment">
            <loading-spinner style="margin-top: 20px"> </loading-spinner>
          </div>
          <div class="col-md-12" id="payment-info">
            <h5>Payment Information</h5>
            <hr />
            <ul class="payment-type">
              <li>
                <label for="">
                  <img
                    src="@/assets/images/icon/png/card.png"
                    width="20%"
                    alt=""
                  />
                  <span>Card Payment</span></label
                >

                <div
                  style="padding: 10px; background: #eef0f8; margin-top: 10px"
                >
                  <div class="d-flex">
                    <label
                      for=""
                      style="
                        margin-top: 5px;
                        width: 60%;
                        float: left;
                        font-weight: 500;
                      "
                      >Supported Cards</label
                    >
                    <img
                      style="width: 40%; float: left"
                      src="@/assets/images/card/card_icon_v1_all.svg"
                      alt=""
                    />
                  </div>
                  <hr />
                  <div class="row">
                    <div class="form-group col-md-6 col-6 cd-field">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Card Number</label
                      >
                      <!-- <div id="myToken"></div> -->
                      <div class="hidden" name="mytoken" id="mytoken"></div>
                      <!-- <input type="hidden" name="cardtype" id="cardtype" /> -->
                      <form name="tokenform" id="tokenform">
                        <iframe
                          id="tokenframe"
                          name="tokenframe"
                          scrolling="no"
                          height="52"
                          width="200"
                          autofocus="true"
                          frameborder="0"
                        ></iframe>
                      </form>
                      <div id="cc_error" class="cd-err-row hidden">
                        Please enter a valid credit card number
                      </div>
                      <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_number']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" && errors["card_number"][0]
                        }}</span
                      >
                      <div
                        class="input-group-prepend"
                        v-if="getPaymentCardType != ''"
                      >
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          style="padding: 0; background: white; border: none"
                        >
                          <img
                            :src="
                              require('@/assets/images/card/card_icon_v1_' +
                                getPaymentCardType +
                                '.svg')
                            "
                            width="85%"
                            height="30px"
                            alt=""
                          />
                        </span>
                      </div>
                      <!-- <div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter card Number"
                          :value="getPaymentCardNumber"
                          @input="updateCardNumber"
                          v-mask="'#### #### #### ####'"
                        />
                        <img
                          src="@/assets/images/card/card_icon_v1_visa.svg"
                          alt=""
                        />
                      </div> -->
                      <!-- <small id="emailHelp" class="form-text  text-danger"
                      >Card No. cannot be empty</small
                    > -->
                      <!-- <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter card Number"
                        :value="getPaymentCardNumber"
                        @input="updateCardNumber"
                        v-mask="'#### #### #### ####'"
                        :class="
                          this.getPaymentType == 'C' && errors['card_number']
                            ? 'border border-danger'
                            : ''
                        "
                      />
                      <div
                        class="input-group-prepend"
                        v-if="getPaymentCardType != ''"
                      >
                        <span
                          class="input-group-text"
                          id="basic-addon1"
                          style="padding: 0; background: white; border: none"
                        >
                          <img
                            :src="
                              require('@/assets/images/card/card_icon_v1_' +
                                getPaymentCardType +
                                '.svg')
                            "
                            width="85%"
                            height="30px"
                            alt=""
                          />
                        </span>
                      </div>
                    </div> -->
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Expiration date</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="MM/YY"
                        v-mask="'##/##'"
                        @input="updateCardExpiry"
                        :value="getPaymentCardExpiry"
                        :class="
                          this.getPaymentType == 'C' && errors['card_expiry']
                            ? 'border border-danger'
                            : ''
                        "
                      />

                      <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_expiry']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" && errors["card_expiry"][0]
                        }}</span
                      >
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Cardholder Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Cardholder Name"
                        :value="getPaymentCardHolderName"
                        @input="updateCardHolderName"
                        :class="
                          this.getPaymentType == 'C' &&
                          errors['card_holder_name']
                            ? 'border border-danger'
                            : ''
                        "
                      />
                      <span
                        v-if="
                          this.getPaymentType == 'C' &&
                            errors['card_holder_name']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" &&
                            errors["card_holder_name"][0]
                        }}</span
                      >
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 text-uppercase mb-1 paymemt-label"
                        >Security Code</label
                      >

                      <input
                        type="text"
                        class="form-control"
                        max="9999"
                        :placeholder="getPaymentCardProperty.cvv_placeholder"
                        v-mask="getPaymentCardProperty.cvv_mask"
                        @input="updateCardCvvNumber"
                        :value="getPaymentCardCvvNumber"
                        :class="
                          this.getPaymentType == 'C' && errors['card_cvv_no']
                            ? 'border border-danger'
                            : ''
                        "
                      />

                      <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_cvv_no']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" && errors["card_cvv_no"][0]
                        }}</span
                      >
                    </div>
                  </div>
                  <hr />
                  <div
                    style="
                      padding: 0 0 1%;
                      background: #eef0f8;
                      margin-top: 10px;
                    "
                  >
                    <div class="d-flex">
                      <label
                        for=""
                        style="width: 60%; float: left; font-weight: 500"
                        >Billing Address</label
                      >
                    </div>
                    <span style="opacity: 0.5"
                      >As it appears on credit card statement.</span
                    >
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Address</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Address"
                        :value="getPaymentCardHolderAddress"
                        @input="updateCardAddress"
                      />
                      <!-- <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_address']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" &&
                            errors["card_address"][0]
                        }}</span
                      > -->
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >City</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="City"
                        :value="getPaymentCardHolderCity"
                        @input="updateCardcity"
                      />
                      <!-- <span
                        v-if="this.getPaymentType == 'C' && errors['card_city']"
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" && errors["card_city"][0]
                        }}</span
                      > -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Country</label
                      >
                      <select class="form-control" @change="updateCountry">
                        <option value="" hidden>Select Country</option>
                        <option
                          v-for="(item, index) in getCountries"
                          :key="index"
                          :value="item.country_code"
                          :selected="
                            getPaymentCardHolderCountry == item.country_code
                          "
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <!-- <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_country']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" &&
                            errors["card_country"][0]
                        }}</span
                      > -->
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >State</label
                      >
                      <select
                        class="form-control"
                        @change="updateState"
                        v-if="getPaymentCardHolderCountry == 'US'"
                      >
                        <option value="">Select State</option>
                        <option
                          v-for="(item, index) in getStates"
                          :key="index"
                          :value="item.name"
                          :selected="getPaymentCardHolderState == item.name"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <input
                        v-else
                        type="text"
                        class="form-control"
                        placeholder="State"
                        :value="getPaymentCardHolderState"
                        @input="updateState"
                      />
                      <!-- <span
                        v-if="
                          this.getPaymentType == 'C' && errors['card_state']
                        "
                        class="text-danger"
                        >{{
                          this.getPaymentType == "C" && errors["card_state"][0]
                        }}</span
                      > -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Postal/Zip</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Postal/Zip"
                        @input="updatePostalZip"
                        :value="getPaymentCardPostalZip"
                      />
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label
                        for="exampleInputEmail1"
                        class="ml-0 mb-1 text-uppercase paymemt-label"
                        >Phone</label
                      >
                      <div class="input-group mb-3">
                        <div
                          class="input-group-prepend"
                          @click="toggleCountriesPhoneCode(true)"
                          style="cursor: pointer; border-right: 1px solid"
                        >
                          <span
                            class="input-group-text"
                            id="basic-addon1"
                            style="
                              min-width: 80px;
                              max-width: 80px;
                              text-align: center;
                            "
                          >
                            <img
                              :src="getPaymentCardPhonecode.flag"
                              alt=""
                              class="mr-1"
                            />
                            <b style="width: 100%; text-align: center">{{
                              getPaymentCardPhonecode.dial_code
                            }}</b>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          :value="getPaymentCardPhone"
                          @input="updatePaymentCardPhone"
                          v-mask="'(###)-###-####'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <div class="form-group mt-0 mb-0" style="display: inline-flex">
                <input
                  type="checkbox"
                  name="on_site_payment"
                  id="on_site_payment"
                  v-model="onSitePayment"
                  @change="updatePaymentType(1)"
                />
                <label
                  class="ml-2"
                  for="on_site_payment"
                  style="margin-top: 11px"
                  >Click here for on-site payment</label
                >
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="col-md-6 content d-flex flex-column flex-column-fluid new-service"
        v-if="this.getServiceProviderData.payment_method == 'heartland'"
        :style="
          getServiceProviderData.payment_method == 'heartland' && paymentMethod
            ? 'display:block;'
            : 'display:none!important;'
        "
      >
        <div class="col-md-12" id="heart-loading-payment">
          <loading-spinner style="margin-top: 20px"> </loading-spinner>
        </div>
        <div class="row" id="heart-payment-info">
          <div class="col-md-12">
            <h5>Payment Information</h5>
            <hr />
            <ul class="payment-type">
              <li>
                <div style="padding: 10px; background: #eef0f8">
                  <div class="hidden" name="mytoken" id="mytoken"></div>
                  <div class="hidden" name="expiremonth" id="expiremonth"></div>
                  <div class="hidden" name="expireyear" id="expireyear"></div>
                  <div class="hidden" name="card_type" id="card_type"></div>
                  <div class="hidden" name="last_four" id="last_four"></div>
                  <div class="hidden" name="token_type" id="token_type"></div>

                  <div class="form-wrapper">
                    <form id="iframes" action="" method="GET">
                      <div id="ss-banner"></div>

                      <div class="form-row">
                        <label for="iframesCardNumber">Card Number:</label>
                        <div id="iframesCardNumber"></div>
                      </div>

                      <div class="form-row">
                        <label for="iframesCardExpiration"
                          >Card Expiration:</label
                        >
                        <div id="iframesCardExpiration"></div>
                      </div>

                      <div class="form-row">
                        <label for="iframesCardCvv">Card CVV:</label>
                        <div id="iframesCardCvv"></div>
                      </div>
                      <br />
                      <!-- <div id="iframesSubmit"></div> -->
                    </form>
                    <!-- <div class="form-row" style="margin-top: -3%">
                      <label for="iframesCardCvv">cvv</label>
                      <input
                        style=""
                        type="text"
                        class="cvv"
                        placeholder="cvv"
                        @input="updateCardCvvNumber"
                        :value="getPaymentCardCvvNumber"
                      />
                    </div> -->
                    <div class="form-row">
                      <label for="iframesCardCvv">Address</label>
                      <input
                        style=""
                        type="text"
                        class="cvv"
                        placeholder="Address"
                        :value="getPaymentCardHolderAddress"
                        @input="updateCardAddress"
                      />
                    </div>
                    <!-- <div class="form-row">
                      <label for="iframesCardCvv">City</label>
                      <input
                        style=""
                        type="text"
                        class="cvv"
                        placeholder="City"
                        :value="getPaymentCardHolderCity"
                        @input="updateCardcity"
                      />
                    </div>

                    <div class="form-row">
                      <label for="iframesCardCvv">State</label>
                      <select
                        class="form-control"
                        @change="updateState"
                        style="min-width:100%"
                      >
                        <option value="">Select State</option>
                        <option
                          v-for="(item, index) in getStates"
                          :key="index"
                          :value="item.name"
                          :selected="getPaymentCardHolderState == item.name"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div> -->
                    <div class="form-row">
                      <label for="iframesCardCvv">Postal/Zip</label>
                      <input
                        style=""
                        type="text"
                        class="cvv"
                        placeholder="Postal/Zip"
                        @input="updatePostalZip"
                        :value="getPaymentCardPostalZip"
                      />
                    </div>
                  </div>
                </div>
              </li>
              <div class="form-group mt-0 mb-0" style="display: inline-flex">
                <input
                  type="checkbox"
                  name="on_site_payment"
                  id="on_site_payment"
                  v-model="onSitePayment"
                  @change="updatePaymentType(1)"
                />
                <label
                  class="ml-2"
                  for="on_site_payment"
                  style="margin-top: 11px"
                  >Click here for on-site payment</label
                >
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import vCountriesForPhoneCode from "@/components/frontend/modal/countriesforphonecode";
import loadingSpinner from "@/components/frontend/spinner.vue";
import { load } from "recaptcha-v3";

/*  eslint-disable */
import Heartland from "@/assets/js/components/Heartland.js";
window.Heartland = Heartland;
import moment from "moment";
/* eslint-enable */

import {
  UPDATE_ABBREVIATION_CODE,
  UPDATE_PAYMENT_TYPE,
  SET_PAYMENT_CARD_NUMBER,
  SET_PAYMENT_CARD_HOLDER_NAME,
  SET_PAYMENT_CARD_ADDRESS,
  SET_PAYMENT_CARD_EXPIRY_DATE,
  SET_PAYMENT_CARD_CVV_NUMBER,
  SET_PAYMENT_CARD_TYPE,
  SET_CHECK_F_ABBREVIATION_CODE,
  SET_RATE_TOTAL_AMOUNT,
  SET_ADD_RATE_QTY,
  SET_PAYMENT_CARD_PROPERTY,
  CARD_STATE,
  CARD_COUNTRY,
  SET_PAYMENT_CARD_CITY,
  SET_PAYMENT_CARD_NUMBER_POSTAL,
  SET_PAYMENT_CARD_NUMBER_PHONE,
  ISHEARTSPAYMENT,
  SET_QTY,
  RECAPTCHATOKEN,
  SET_TOTAL_SERVICE_AMOUNT,
  SET_ARCHIVAL_PERIOD_ID,
  // SET_F_ARCHIVAL_CLIENT_RATE
  SET_ADDITIONAL_CARD_TOTAL_AMOUNT,
  SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
  UPDATE_PROMO_CODE,
  SET_CHECK_F_PROMO_CODE,
  SET_PROMOCODE_DISCOUNT_AMOUNT,
  SET_PROMOCODE_DISCOUNT_PERCENTAGE,
  SET_ARCHIVAL_RENEW_DATE,
  SET_ARCHIVAL_CUSTOM_DATE,
  SET_ARCHIVAL_CUSTOM_PRICE,
  SET_ARCHIVAL_CUSTOM,
  UPDATE_SERVICE_ORI_CODE,
  UPDATE_ORI,
  SET_CHECK_F_SERVICE_ORI_CODE,
  SET_F_CLIENT_RATE
} from "@/core/services/store/mutations.type";
import {
  CHECK_F_PROMO_CODE,
  CHECK_F_ARCHIVAL_ABBREVIATION_CODE,
  RECAPTCHATOKENVERIFY,
  CHECK_F_SERVICE_ORI_CODE
} from "@/core/services/store/actions.type";
export default {
  computed: {
    ...mapGetters([
      "getArchivalDate",
      "getArchivalRates",
      "getTotalServiceAmount",
      "getTotalAdditionalAmount",
      "getfClientRate",
      "getTotalRateAmount",
      "getAddQty",
      "getPromoCode",
      "getAbbreviationCode",
      "updateAbbreviationCode",
      "updatePromoCode",
      "getPaymentType",
      "getClientEnableOnSitePayment",
      "getSettingNote",
      "getPaymentCardNumber",
      "getPaymentCardHolderName",
      "getPaymentCardExpiry",
      "getPaymentCardCvvNumber",
      "getPaymentCardType",
      "getPaymentCardProperty",
      "getPaymentCardHolderAddress",
      "getPaymentCardHolderState",
      "getPaymentCardHolderCountry",
      "getPaymentCardHolderCity",
      "getStates",
      "getCountries",
      "getPaymentCardPhone",
      "getPaymentCardPostalZip",
      "getPaymentCardPhonecode",
      "getServiceProviderData",
      "getQty",
      "getTotalArchivalRateAmount",
      "getArchivalPeriodId",
      "getServiceOriCode",
      "updateServiceOriCode",
      "getServiceTypeCode",
      "getTotalDiscountAmount",
      "getPromoCodeDiscountRate",
      "getArchivalRenewDate",
      "getServiceId",
      "getEnableOriRate"
    ])
  },
  components: {
    "v-countries-modal": vCountriesForPhoneCode,
    loadingSpinner
  },
  props: {
    front_status: {
      type: Boolean,
      default: true
    },
    is_both_card: {
      type: Boolean,
      default: true
    },
    is_archival: {
      type: Boolean,
      default: false
    },
    is_renew: {
      type: Boolean,
      default: false
    },
    enrollData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      custom_archival: false,
      custom_archival_date: "",
      custom_archival_price: 0,
      showLoading: false,
      paymentMode: "",
      archival_period: "",
      archivalItem: 0,
      selected_renew_date: "",
      set_lower_limit: "",
      set_upper_limit: "",
      sitekey: "6LevxIAfAAAAAB5NDxwhJqj0hGMUI3Ydw0nWIBlO",
      errors: [],
      service_ori: "",
      service_ori_code_error: false,
      addQty: 0,
      paymentMethod: false,
      promo_code: "",
      promo_code_error: false,
      expirationDate: "",
      onSitePayment: false,
      abree_code: "",
      abbreviation_code: "",
      abbreviation_code_error: false,
      myCustomCardTypes: [
        {
          regEx: /^4[0-9]{5}/gi,
          name: "visa",
          icon: require("@/assets/visa_icon.png")
        },
        {
          regEx: /^5[1-5][0-9]{4}/gi,
          name: "mastercard",
          icon: require("@/assets/master_icon.png")
        }
      ],
      showCountriesWithPhoneCode: false,
      id_scroll_list_country: "",
      token: "",
      hps: "",
      qty: 0
    };
  },
  // created() {},
  mounted() {
    this.archival_period = this.getArchivalPeriodId;
    this.archivalDataFetched(this.archival_period, 0, false);
    this.selected_renew_date = "";
    this.qty = this.getQty;
    this.addQty = this.getAddQty;
    this.onSitePayment = this.getPaymentType == "O" ? 1 : 0;
    if (this.updateAbbreviationCode != "") {
      this.abree_code = this.updateAbbreviationCode;
      this.checkAbbreviationCode();
    } else {
      this.paymentMethod = true;
    }
    if (this.updatePromoCode != "") {
      this.promo_code = this.updatePromoCode;
      this.checkPromoCode();
    }
    let payment_method = this.getServiceProviderData.payment_method;
    this.$store.commit(
      ISHEARTSPAYMENT,
      payment_method == "heartland" ? true : false
    );
    /* eslint-disable */
    payment_method =
      this.getServiceProviderData.payment_method == "heartland" ? true : false;
    if (payment_method && this.getServiceProviderData != "") {
      setTimeout(function () {
        document.getElementById("heart-payment-info").style.display = "block";
        document.getElementById("heart-loading-payment").style.display = "none";
      }, 2);
      this.hps = new Heartland.HPS({
        publicKey: this.getServiceProviderData.public_key,
        type: "iframe",
        // Configure the iframe fields to tell the library where
        // the iframe should be inserted into the DOM and some
        // basic options
        fields: {
          cardNumber: {
            target: "iframesCardNumber",
            placeholder: "•••• •••• •••• ••••",
          },
          cardExpiration: {
            target: "iframesCardExpiration",
            placeholder: "MM / YYYY",
          },
          cardCvv: {
            target: "iframesCardCvv",
            placeholder: "CVV",
          },
          // submit: {
          //   value: "Pay $10.00",
          //   target: "iframesSubmit"
          // }
        },
        // Collection of CSS to inject into the iframes.
        // These properties can match the site's styles
        // to create a seamless experience.
        style: {
          input: {
            background: "#fff",
            border: "1px solid",
            "border-color": "#bbb3b9 #c7c1c6 #c7c1c6",
            "box-sizing": "border-box",
            "font-family": "serif",
            "font-size": "16px",
            "line-height": "1",
            margin: "0 .5em 0 0",
            "max-width": "100%",
            outline: "0",
            padding: "0.5278em",
            "vertical-align": "baseline",
            height: "50px",
            width: "100% !important",
          },
          "#heartland-field": {
            "font-family": "sans-serif",
            "box-sizing": "border-box",
            display: "block",
            height: "50px",
            padding: "6px 12px",
            "font-size": "14px",
            "line-height": "1.42857143",
            color: "#555",
            "background-color": "#fff",
            border: "1px solid #ccc",
            "border-radius": "0px",
            "-webkit-box-shadow": "inset 0 1px 1px rgba(0,0,0,.075)",
            "box-shadow": "inset 0 1px 1px rgba(0,0,0,.075)",
            "-webkit-transition":
              "border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s",
            "-o-transition":
              "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
            transition:
              "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
            width: "100%",
          },
          "#heartland-field[name=submit]": {
            "background-color": "#36b46e",
            "font-family": "sans-serif",
            "text-transform": "uppercase",
            color: "#ffffff",
            border: "0px solid transparent",
            // display: "none"
          },
          "#heartland-field[name=submit]:focus": {
            color: "#ffffff",
            "background-color": "#258851",
            outline: "none",
          },
          "#heartland-field[name=submit]:hover": {
            "background-color": "#258851",
          },
          "#heartland-field-wrapper #heartland-field:focus": {
            border: "1px solid #3989e3",
            outline: "none",
            "box-shadow": "none",
            height: "50px",
          },
          "heartland-field-wrapper #heartland-field": {
            height: "50px",
          },
          "input[type=submit]": {
            "box-sizing": "border-box",
            display: "inline-block",
            padding: "6px 12px",
            "margin-bottom": "0",
            "font-size": "14px",
            "font-weight": "400",
            "line-height": "1.42857143",
            "text-align": "center",
            "white-space": "nowrap",
            "vertical-align": "middle",
            "-ms-touch-action": "manipulation",
            "touch-action": "manipulation",
            cursor: "pointer",
            "-webkit-user-select": "none",
            "-moz-user-select": "none",
            "-ms-user-select": "none",
            "user-select": "none",
            "background-image": "none",
            border: "1px solid transparent",
            "border-radius": "4px",
            color: "#fff",
            "background-color": "#337ab7",
            "border-color": "#2e6da4",
          },
          "#heartland-field[placeholder]": {
            "letter-spacing": "3px",
          },
          "#heartland-field[name=cardCvv]": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/cvv1.png?raw=true) no-repeat right",
            "background-size": "63px 40px",
          },
          "input#heartland-field[name=cardNumber]": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-inputcard-blank@2x.png?raw=true) no-repeat right",
            "background-size": "55px 35px",
          },
          "#heartland-field.invalid.card-type-visa": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right",
            "background-size": "83px 88px",
            "background-position-y": "-44px",
          },
          "#heartland-field.valid.card-type-visa": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right top",
            "background-size": "82px 86px",
          },
          "#heartland-field.invalid.card-type-discover": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right",
            "background-size": "85px 90px",
            "background-position-y": "-44px",
          },
          "#heartland-field.valid.card-type-discover": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right",
            "background-size": "85px 90px",
            "background-position-y": "1px",
          },
          "#heartland-field.invalid.card-type-amex": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right",
            "background-size": "50px 90px",
            "background-position-y": "-44px",
          },
          "#heartland-field.valid.card-type-amex": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right top",
            "background-size": "50px 90px",
          },
          "#heartland-field.invalid.card-type-mastercard": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right",
            "background-size": "62px 105px",
            "background-position-y": "-52px",
          },
          "#heartland-field.valid.card-type-mastercard": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right",
            "background-size": "62px 105px",
            "background-position-y": "-1px",
          },
          "#heartland-field.invalid.card-type-jcb": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right",
            "background-size": "55px 94px",
            "background-position-y": "-44px",
          },
          "#heartland-field.valid.card-type-jcb": {
            background:
              "transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right top",
            "background-size": "55px 94px",
            "background-position-y": "2px",
          },
          "input#heartland-field[name=cardNumber]::-ms-clear": {
            display: "none",
          },
        },
        onTokenSuccess: function (resp) {
          // console.log(resp)
          document.getElementById("mytoken").innerHTML = resp.token_value;
          document.getElementById("expiremonth").innerHTML = resp.exp_month;
          document.getElementById("expireyear").innerHTML = resp.exp_year;
          document.getElementById("token_type").innerHTML = resp.token_type;
          document.getElementById("last_four").innerHTML = resp.last_four;
          document.getElementById("card_type").innerHTML = resp.card_type;
        },
        onTokenError: function () {},
        onEvent: function () {},
      });
    }
    /* eslint-enable */
    if (!payment_method && this.getServiceProviderData != "") {
      let frame = document.getElementById("tokenframe");
      frame.addEventListener("load", () => {
        setTimeout(function() {
          document.getElementById("payment-info").style.display = "block";
          document.getElementById("loading-payment").style.display = "none";
        }, 2);
      });
      let mytoken = document.getElementById("mytoken");
      mytoken.innerHTML = "";
      // document.getElementById("myToken").style.display = "none";
      window.addEventListener(
        "message",
        function(event) {
          let token;
          mytoken.innerHTML = "";

          token = JSON.parse(event.data);

          if (token.validationError != undefined) {
            document.getElementById("tokenframe").focus();
            let error = document.getElementById("cc_error");
            error.style.display = "block";
            mytoken.innerHTML = "";

            setTimeout(function() {
              error.style.display = "none";
            }, 100);
            return false;
          }
          // alert("a", token.message);
          mytoken.innerHTML = token.message;
          // document.getElementById("myToken").fadeIn();
        },
        false
      );
      this.setFrame();
    }
    // this.setIframe();
    // alert("moun");
    // this.recaptchafun();
    // let add_rate = parseFloat(this.getfClientRate.rate);
    // this.$store.commit(SET_RATE_TOTAL_AMOUNT, add_rate.toFixed(2));
  },
  filters: {
    taxAmount(amount, taxPer) {
      amount = parseFloat(amount);
      taxPer = parseFloat(taxPer);
      return ((amount * taxPer) / 100).toFixed(2);
    },
    toFixedTwoDigit(amount, data) {
      var rate = parseFloat(data.rate) * parseInt(data.service.default_qty);
      var tp = parseFloat(data.tax_percentage);
      return (parseFloat(amount) + (rate + (rate * tp) / 100)).toFixed(2);
    },
    toFixedTwoDigitRate(data) {
      var rate = parseFloat(data.rate) * parseInt(data.service.default_qty);
      var tp = parseFloat(data.tax_percentage);

      return (rate + (rate * tp) / 100).toFixed(2);
    }
  },
  methods: {
    set_custom_archival() {
      // console.log(event.target.value);
      if (this.custom_archival) {
        this.$store.commit(SET_ARCHIVAL_RENEW_DATE, "");
        this.$store.commit(SET_ARCHIVAL_PERIOD_ID, "");
        this.archivalItem = 0;
        this.archival_period = "";
        this.selected_renew_date = "";
        this.$store.commit(SET_ARCHIVAL_TOTAL_RATE_AMOUNT, 0);
        this.$store.commit(SET_ARCHIVAL_CUSTOM, true);
      } else {
        this.$store.commit(SET_ARCHIVAL_CUSTOM, false);
        this.$store.commit(SET_ARCHIVAL_CUSTOM_DATE, "");
        this.$store.commit(SET_ARCHIVAL_CUSTOM_PRICE, 0);
        this.custom_archival_date = "";
        this.custom_archival_price = 0;
      }
      // this.custom_archival = !this.custom_archival;
      // console.log(this.custom_archival);
    },
    setCustomArchivalDate() {
      this.$store.commit(SET_ARCHIVAL_CUSTOM_DATE, this.custom_archival_date);
    },
    setCustomPrice() {
      this.$store.commit(SET_ARCHIVAL_CUSTOM_PRICE, this.custom_archival_price);
      this.$store.commit(
        SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
        this.custom_archival_price
      );
    },
    setArchival(e) {
      this.archivalItem = [];
      var id = e.target.value;

      this.archivalDataFetched(id, this.getTotalArchivalRateAmount, true);
    },
    setRenewDate() {
      this.$store.commit(SET_ARCHIVAL_RENEW_DATE, this.selected_renew_date);
    },

    archivalDataFetched(id, old_archival_amount = 0, flag = false) {
      var key = this.getArchivalRates.findIndex(
        x => x.archival_period.id == id
      );
      // var old_archival_amount = ;

      this.archival_total = 0;
      if (flag) {
        if (key > -1) {
          if (this.is_renew) {
            // console.log(this.getArchivalDate);
            var duration = this.getArchivalRates[key].archival_period.duration;
            if (!this.getArchivalDate == "") {
              var dateObject = new Date(this.getArchivalDate);

              dateObject.setFullYear(dateObject.getFullYear() + duration);
            } else {
              dateObject = new Date();

              dateObject.setFullYear(dateObject.getFullYear() + duration);
            }
            this.selected_renew_date = moment(dateObject).format("YYYY-MM-DD");
            var set_date = new Date(this.selected_renew_date);
            this.set_upper_limit = set_date.setMonth(set_date.getMonth() + 3);
            document.getElementById("inputdate").max = moment(
              this.set_upper_limit
            ).format("YYYY-MM-DD");

            this.set_lower_limit = set_date.setMonth(set_date.getMonth() - 6);
            document.getElementById("inputdate").min = moment(
              this.set_lower_limit
            ).format("YYYY-MM-DD");
          }

          this.$store.commit(SET_ARCHIVAL_RENEW_DATE, this.selected_renew_date);
          this.archivalItem = this.getArchivalRates[key];
          var amount = parseFloat(this.getArchivalRates[key].rate);
          var taxPer = parseFloat(this.getArchivalRates[key].tax_percentage);
          var tax = parseFloat(((amount * taxPer) / 100).toFixed(2));
          this.archival_total = amount + tax;
          this.$store.commit(SET_ARCHIVAL_PERIOD_ID, id);
        } else {
          this.archivalItem = 0;
          if (!this.getArchivalDate == "") {
            dateObject = new Date(this.getArchivalDate);
          } else {
            dateObject = new Date();
          }
          this.selected_renew_date = moment(dateObject).format("YYYY-MM-DD");
          set_date = new Date(this.selected_renew_date);
          this.set_upper_limit = set_date.setMonth(set_date.getMonth() + 3);
          document.getElementById("inputdate").max = moment(
            this.set_upper_limit
          ).format("YYYY-MM-DD");

          this.set_lower_limit = set_date.setMonth(set_date.getMonth() - 6);
          document.getElementById("inputdate").min = moment(
            this.set_lower_limit
          ).format("YYYY-MM-DD");
          this.$store.commit(SET_ARCHIVAL_PERIOD_ID, "");
          this.$store.commit(SET_ARCHIVAL_RENEW_DATE, "");
          this.selected_renew_date = "";
        }
        var old_discount_amount = (
          (old_archival_amount * parseFloat(this.getPromoCodeDiscountRate)) /
          100
        ).toFixed(2);
        var archival_discount_amount = (
          (parseFloat(this.archival_total) *
            parseFloat(this.getPromoCodeDiscountRate)) /
          100
        ).toFixed(2);
        var discount_amount = (
          parseFloat(this.getTotalDiscountAmount) +
          parseFloat(archival_discount_amount) -
          parseFloat(old_discount_amount)
        ).toFixed(2);
        this.$store.commit(SET_PROMOCODE_DISCOUNT_AMOUNT, discount_amount);

        old_archival_amount = (
          old_archival_amount -
          (old_archival_amount * this.getPromoCodeDiscountRate) / 100
        ).toFixed(2);

        this.$store.commit(
          SET_TOTAL_SERVICE_AMOUNT,
          (
            this.archival_total +
            parseFloat(this.getTotalServiceAmount) -
            (parseFloat(this.archival_total) * this.getPromoCodeDiscountRate) /
              100 +
            -parseFloat(old_archival_amount)
          ).toFixed(2)
        );

        this.$store.commit(
          SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
          parseFloat(this.archival_total)
        );
      } else {
        if (key > -1) {
          this.archivalItem = this.getArchivalRates[key];
        }
      }

      // this.setQty();

      // this.$store.commit(SET_ADD_RATE_QTY, this.addQty);
      // var add_rate_ = parseFloat(this.rateData.additional_rate);

      // var tax_percentage = parseFloat(this.rateData.tax_percentage);

      // this.$store.commit(
      //   SET_RATE_TOTAL_AMOUNT,
      //   (
      //     this.addQty * add_rate_ +
      //     (this.addQty * add_rate_ * tax_percentage) / 100 +
      //     this.archival_total
      //   ).toFixed(2)
      // );
    },
    // changeArchivalAmount($event) {
    //   console.log($event.target.value);
    //   if ($event.target.value == "") {
    //     this.$store.commit(SET_ARCHIVAL_TOTAL_RATE_AMOUNT, parseFloat(0));
    //   } else {
    //     this.$store.commit(
    //       SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
    //       parseFloat($event.target.value)
    //     );
    //   }
    //   // console.log(this.getTotalArchivalRateAmount);
    // },
    async recaptchafun() {
      const recaptcha = await load(this.sitekey, {
        autoHideBadge: true
      });
      const token = await recaptcha.execute("pay");

      // console.log(token); // Will also print the token
      this.$store.dispatch(RECAPTCHATOKENVERIFY, token).then(res => {
        let data = JSON.parse(res);
        if (data.success) {
          this.$store.commit(RECAPTCHATOKEN, token);
        }
      });
    },
    setQty() {
      this.$store.commit(SET_QTY, this.qty);
      var add_rate = parseFloat(this.getfClientRate.rate);
      var tax_amt = (add_rate * this.getfClientRate.tax_percentage) / 100;
      this.$store.commit(
        SET_RATE_TOTAL_AMOUNT,
        (this.qty * (add_rate + tax_amt)).toFixed(2)
      );
      this.$store.commit(
        SET_TOTAL_SERVICE_AMOUNT,
        (
          parseFloat(this.qty * (add_rate + tax_amt)) +
          parseFloat(this.getTotalArchivalRateAmount)
        ).toFixed(2)
      );
    },
    setFrame() {
      let url = this.getServiceProviderData.token_url;

      setTimeout(function() {
        document.getElementById("tokenframe").src = url;
        this.showLoading = false;
      }, 2);
      // alert(url);/
    },
    toggleCountriesPhoneCode(flag) {
      if (flag) {
        this.id_scroll_list_country =
          "scroll_li" + this.getPaymentCardPhonecode.code;
      }
      this.showCountriesWithPhoneCode = flag;
      this.$refs.email.$el.focus();
    },
    updatePostalZip(e) {
      var value = e.target.value;

      this.$store.commit(SET_PAYMENT_CARD_NUMBER_POSTAL, value);
    },
    updatePaymentCardPhone(e) {
      var value = e.target.value;
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE, value);
    },
    addQty_() {
      this.addQty = this.addQty + 1;
      this.$store.commit(SET_ADD_RATE_QTY, this.addQty);
      var add_rate = parseFloat(this.getfClientRate.additional_rate);
      var tax_percentage = parseFloat(this.getfClientRate.tax_percentage);

      this.$store.commit(
        SET_ADDITIONAL_CARD_TOTAL_AMOUNT,
        (
          this.addQty * add_rate +
          (this.addQty * add_rate * tax_percentage) / 100
        ).toFixed(2)
      );
      var discount_amount = (
        ((parseFloat(this.getTotalAdditionalAmount) +
          parseFloat(this.getTotalRateAmount) +
          parseFloat(this.getTotalArchivalRateAmount)) *
          parseFloat(this.getPromoCodeDiscountRate)) /
        100
      ).toFixed(2);
      this.$store.commit(SET_PROMOCODE_DISCOUNT_AMOUNT, discount_amount);
      this.$store.commit(
        SET_TOTAL_SERVICE_AMOUNT,
        (
          parseFloat(this.getTotalAdditionalAmount) +
          parseFloat(this.getTotalRateAmount) +
          parseFloat(this.getTotalArchivalRateAmount) -
          discount_amount
        ).toFixed(2)
      );
    },
    miuQty_() {
      if (this.addQty > 0) {
        this.addQty = this.addQty - 1;
        this.$store.commit(SET_ADD_RATE_QTY, this.addQty);
        var add_rate = parseFloat(this.getfClientRate.additional_rate);
        var tax_percentage = parseFloat(this.getfClientRate.tax_percentage);
        this.$store.commit(
          SET_ADDITIONAL_CARD_TOTAL_AMOUNT,
          (
            this.addQty * add_rate +
            (this.addQty * add_rate * tax_percentage) / 100
          ).toFixed(2)
        );

        var discount_amount = (
          ((parseFloat(this.getTotalAdditionalAmount) +
            parseFloat(this.getTotalRateAmount) +
            parseFloat(this.getTotalArchivalRateAmount)) *
            parseFloat(this.getPromoCodeDiscountRate)) /
          100
        ).toFixed(2);
        this.$store.commit(SET_PROMOCODE_DISCOUNT_AMOUNT, discount_amount);
        this.$store.commit(
          SET_TOTAL_SERVICE_AMOUNT,
          (
            parseFloat(this.getTotalAdditionalAmount) +
            parseFloat(this.getTotalRateAmount) +
            parseFloat(this.getTotalArchivalRateAmount) -
            discount_amount
          ).toFixed(2)
        );
      }
    },
    updateAbCode(e) {
      this.$store.commit(UPDATE_ABBREVIATION_CODE, e.target.value);
      this.abbreviation_code_error = false;
      if (this.getAbbreviationCode.data.length < 1) {
        this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
          data: { show_payment_option: true },
          success: "none"
        });
      }

      //     this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
      //   data: [],success:true
      // });
    },
    updatePRCode(e) {
      this.$store.commit(UPDATE_PROMO_CODE, e.target.value);
      this.promo_code_error = false;
      if (this.getPromoCode.data.length < 1) {
        this.$store.commit(SET_CHECK_F_PROMO_CODE, {
          data: { discount_percentage: 0 },
          success: "none"
        });
      }

      //     this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
      //   data: [],success:true
      // });
    },
    change_abbr() {},

    updateCardNumber(cardno) {
      delete this.errors["card_number"];
      var value = cardno;
      if (value.length < 1) {
        this.errors.card_number = {
          0: "Card No. cannot be empty"
        };
      }
      var cardType = "";

      var paymentCardProperty = {
        cvv_placeholder: "3 digit",
        cvv_mask: "###"
      };

      let re = new RegExp("^4");
      if (value.match(re) != null) {
        cardType = "visa";
      }

      re = new RegExp("^(34|37)");
      if (value.match(re) != null) {
        cardType = "amex";
        paymentCardProperty = {
          cvv_placeholder: "4 digit",
          cvv_mask: "####"
        };
      }

      re = new RegExp("^5[1-5]");
      if (value.match(re) != null) {
        cardType = "mastercard";
      }

      re = new RegExp("^6011");
      if (value.match(re) != null) {
        cardType = "discover";
      }

      // /The card number entered is not compliant, please check and re-enter.
      //Card No. length is less than the minimum length
      //The card number entered is not compliant, please check and re-enter.

      this.$store.commit(SET_PAYMENT_CARD_PROPERTY, paymentCardProperty);

      if (value.length > 6) {
        if (cardType == "") {
          this.errors.card_number = {
            0: "The card number entered is not compliant, please check and re-enter."
          };
        }
        this.$store.commit(SET_PAYMENT_CARD_TYPE, cardType);
      } else {
        this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
      }
      this.$store.commit(SET_PAYMENT_CARD_NUMBER, value);
    },
    updateCardHolderName(e) {
      delete this.errors["card_holder_name"];
      var value = e.target.value;
      this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, value);
      if (value.length < 1) {
        this.errors.card_holder_name = {
          0: "Card holder name cannot be empty"
        };
      }
    },
    updateCardAddress(e) {
      delete this.errors["card_address"];
      var value = e.target.value;
      this.$store.commit(SET_PAYMENT_CARD_ADDRESS, value);
      if (value.length < 1) {
        this.errors.card_address = {
          0: "Card Address cannot be empty"
        };
      }
    },
    updateCardcity(e) {
      delete this.errors["card_city"];
      var value = e.target.value;
      this.$store.commit(SET_PAYMENT_CARD_CITY, value);
      if (value.length < 1) {
        this.errors.card_city = {
          0: "City cannot be empty"
        };
      }
    },
    updateCardCvvNumber(e) {
      delete this.errors["card_cvv_no"];
      var value = e.target.value;
      if (value.length < 1) {
        this.errors.card_cvv_no = {
          0: "Security Code cannot be empty"
        };
      }
      this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, value);
    },
    updateCardExpiry(e) {
      delete this.errors["card_expiry"];
      // this.errors.card_expiry = null;

      var value = e.target.value;
      this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, value);
      if (value.length < 1) {
        this.errors.card_expiry = {
          0: "Expiration date cannot be empty"
        };
      }
      if (value.length == 5) {
        var today = new Date();
        var someday = new Date();
        someday.setFullYear(
          today
            .getFullYear()
            .toString()
            .substr(0, 2) + value.split("/")[1],
          value.split("/")[0],
          1
        );

        if (someday < today) {
          this.errors.card_expiry = {
            0: "Card Expiration Date is not validated"
          };
        }
      }
    },
    async updatePaymentMode(event) {
      var val = event.target.value;
      this.showLoading = true;

      if (val == 1) {
        this.paymentMode = "";
        var value = "O";
        this.errors = [];
        this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
        this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
        this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
        this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
        this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
        this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
        this.$store.commit(SET_PAYMENT_CARD_CITY, "");
        this.$store.commit(CARD_COUNTRY, "");
        this.$store.commit(CARD_STATE, "");
      } else if (val == 2) {
        this.paymentMode = "card";

        this.setIframe();
        // this.showLoading = false;
        // this.setIframe();

        value = "C";
      } else {
        this.paymentMode = "";
        value = "";
      }
      this.showLoading = false;
      this.$store.commit(UPDATE_PAYMENT_TYPE, value);
    },

    updatePaymentType(value) {
      if (this.onSitePayment) {
        value = "O";
        this.errors = [];
        this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
        this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
        this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
        this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
        this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
        this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
        this.$store.commit(SET_PAYMENT_CARD_CITY, "");
        this.$store.commit(CARD_COUNTRY, "");
        this.$store.commit(CARD_STATE, "");
      } else {
        value = "C";
      }
      this.$store.commit(UPDATE_PAYMENT_TYPE, value);
    },
    updateAbbreviationCodeFun(e) {
      this.$store.commit(UPDATE_ABBREVIATION_CODE, e.target.value);
    },
    removeAbbCode() {
      this.$store.commit(UPDATE_PAYMENT_TYPE, "");
      this.onSitePayment = 0;
      this.$store.commit(UPDATE_ABBREVIATION_CODE, "");
      this.abbreviation_code_error = false;
      this.paymentMethod = true;
      this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
        data: { show_payment_option: true },
        success: "none"
      });
    },
    checkAbbreviationCode() {
      this.$store.commit(UPDATE_ABBREVIATION_CODE, this.abree_code);
      if (this.updateAbbreviationCode != "") {
        this.abbreviation_code_error = true;

        this.$store
          .dispatch(CHECK_F_ARCHIVAL_ABBREVIATION_CODE, {
            code: this.updateAbbreviationCode,
            client_slug: this.$route.params.client_slug
          })
          .then(data => {
            if (data.success) {
              this.paymentMethod = data.data.show_payment_option;
              if (!data.data.show_payment_option) {
                this.$store.commit(UPDATE_PAYMENT_TYPE, "A");
              }
              this.abree_code = "";
            } else {
              this.paymentMethod = true;
            }
          });
      } else {
        this.abbreviation_code_error = false;
      }
    },
    updateState(e) {
      this.$store.commit(CARD_STATE, e.target.value);
      var value = e.target.value;
      if (value.length < 1) {
        this.errors.card_city = {
          0: "State cannot be empty"
        };
      }
    },
    updateCountry(e) {
      this.$store.commit(CARD_COUNTRY, e.target.value);
      this.$store.commit(CARD_STATE, "");
      var value = e.target.value;
      if (value.length < 1) {
        this.errors.card_city = {
          0: "Country cannot be empty"
        };
      }
    },
    removePromoCode() {
      // this.$store.commit(UPDATE_PAYMENT_TYPE, "C");
      // this.onSitePayment = 0;
      this.$store.commit(UPDATE_PROMO_CODE, "");
      this.promo_code_error = false;
      // this.paymentMethod = true;
      this.$store.commit(SET_CHECK_F_PROMO_CODE, {
        data: { discount_percentage: 0 },
        success: "none"
      });
      this.$store.commit(SET_PROMOCODE_DISCOUNT_AMOUNT, 0);
      this.$store.commit(SET_PROMOCODE_DISCOUNT_PERCENTAGE, 0);
      this.$store.commit(
        SET_TOTAL_SERVICE_AMOUNT,
        (
          parseFloat(this.getTotalAdditionalAmount) +
          parseFloat(this.getTotalRateAmount) +
          parseFloat(this.getTotalArchivalRateAmount)
        ).toFixed(2)
      );
    },
    updateOri(e) {
      this.$store.commit(UPDATE_SERVICE_ORI_CODE, e.target.value);
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });
      //     this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
      //   data: [],success:true
      // });
    },
    checkServiceOri() {
      // this.service_ori = this.updateServiceOriCode;

      if (this.updateServiceOriCode != "") {
        this.service_ori_code_error = true;
        var service_id = this.getServiceId;
        this.$store
          .dispatch(CHECK_F_SERVICE_ORI_CODE, {
            code: this.updateServiceOriCode,
            client: this.$route.params.id,
            service: service_id
          })
          .then(data => {
            if (data.success) {
              this.$store.commit(UPDATE_SERVICE_ORI_CODE, data.data.ori_number);
              this.$store.commit(UPDATE_ORI, data.data.service_ori_id);
              this.$store.commit(SET_F_CLIENT_RATE, data.data);
              // this.getfClientRate = data
              if (data.data.service.is_drug_testing) {
                this.qty = this.getQty;
                this.recipient = this.getMultirecipient;
              } else {
                this.qty = data.data.service.default_qty;
              }

              var rate = data.data.rate;

              var tax_percentage_amount =
                (parseFloat(data.data.tax_percentage) / 100) * rate * this.qty;
              //  (rateData.additional_rate * getAddQty)
              //                   | taxAmount(rateData.tax_percentage)
              var tax_additional_amount =
                (parseFloat(data.data.tax_percentage) / 100) *
                data.data.additional_rate *
                this.getAddQty;
              this.$store.commit(
                SET_ADDITIONAL_CARD_TOTAL_AMOUNT,

                (
                  data.data.additional_rate * this.getAddQty +
                  tax_additional_amount
                ).toFixed(2)
              );

              this.$store.commit(
                SET_RATE_TOTAL_AMOUNT,

                (rate * this.qty + tax_percentage_amount).toFixed(2)
              );

              this.$store.commit(
                SET_TOTAL_SERVICE_AMOUNT,

                (rate * this.qty + tax_percentage_amount).toFixed(2)
              );

              this.service_ori = "";
            } else {
              this.service_ori_code_error = true;
            }
          });
      } else {
        this.service_ori_code_error = true;
      }
    },
    removeServiceOri() {
      this.$store.commit(UPDATE_ORI, "");
      //  this.$store.commit(UPDATE_PAYMENT_TYPE,this.getPaymentType);
      this.service_ori_code_error = false;
      this.$store.commit(UPDATE_SERVICE_ORI_CODE, "");
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });
    },
    checkPromoCode() {
      this.$store.commit(UPDATE_PROMO_CODE, this.promo_code);
      if (this.updatePromoCode != "") {
        this.promo_code_error = true;

        this.$store
          .dispatch(CHECK_F_PROMO_CODE, {
            code: this.updatePromoCode,
            client_slug: this.$route.params.client_slug
          })
          .then(data => {
            if (data.success) {
              this.promo_code_error = false;

              // this.paymentMethod = data.data.show_payment_option;
              if (data.data.discount_percentage > 0) {
                this.$store.commit(
                  SET_PROMOCODE_DISCOUNT_PERCENTAGE,
                  parseFloat(data.data.discount_percentage).toFixed(2)
                );
                this.$store.commit(
                  SET_PROMOCODE_DISCOUNT_AMOUNT,
                  (
                    ((parseFloat(this.getTotalRateAmount) +
                      parseFloat(this.getTotalAdditionalAmount) +
                      parseFloat(this.getTotalArchivalRateAmount)) *
                      parseFloat(data.data.discount_percentage)) /
                    100
                  ).toFixed(2)
                );
                this.$store.commit(
                  SET_TOTAL_SERVICE_AMOUNT,
                  (
                    parseFloat(this.getTotalRateAmount) +
                    parseFloat(this.getTotalAdditionalAmount) +
                    parseFloat(this.getTotalArchivalRateAmount) -
                    parseFloat(this.getTotalDiscountAmount)
                  ).toFixed(2)
                );
              }

              this.promo_code = "";
            }
          });
      } else {
        this.promo_code_error = false;
      }
    }
  }
  // components: { VueCreditCard }
};
</script>
